
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../assets/css/global.css';
@import "../../assets/css/antd.css";
.device-data {
	text-align: center;
	float: left;
	height: 32px;
	font-family: PingFangSC-Regular;
	font-size: 14px;
	color: #E2E2E2;
	line-height: 32px;
	font-weight: 400;
}

.device_data_name {
	padding-left: 16px;
	float: left;
	width: 180px;
	height: 32px;
	font-family: PingFangSC-Regular;
	font-size: 14px;
	color: #B7B8BC;
	line-height: 32px;
	font-weight: 400;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
	position: relative;
	padding: 12px 16px;
	padding-left: 40px;
	color: rgba(255, 255, 255, 0.65) !important;
	line-height: 22px;
	cursor: pointer;
	transition: all 0.3s;
}

.ant-collapse {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(255, 255, 255, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	font-feature-settings: 'tnum';
	background-color: transparent !important;
	border: 1px solid rgba(255, 255, 255, 0.12) !important;
	border-bottom: 0;
	border-radius: 4px;
}

.ant-collapse-content {
	overflow: hidden;
	color: rgba(255, 255, 255, 0.65);
	background-color: transparent !important;
	border: 1px solid rgba(255, 255, 255, 0.12) !important;
}

.ant-collapse>.ant-collapse-item {
	border-bottom: none !important;
}

.ant-table-placeholder {
	position: relative;
	z-index: 1;
	margin-top: -1px;
	padding: 16px 16px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 14px;
	text-align: center;
	background: #fff;
	border-top: 1px solid rgba(255, 255, 255, 0.12) !important;
	border-bottom: 1px solid rgba(255, 255, 255, 0.12) !important;
	border-radius: 0 0 4px 4px;
}
